import React from "react";
import { graphql } from "gatsby";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MdxLink from "../components/mdxLink";
import MdxNav from "../components/mdxNav";

import SEO from "../components/seo";
import Wrap from "../components/wrap";

function animalPage({ data: { mdx, nav } }) {
  return (
    <>
      <SEO title={mdx.frontmatter.title} description={mdx.frontmatter.intro} />

      <div className="xl:bg-leaf-3">
        <MdxNav data={nav} />
        <Wrap maxWidth="max-w-2xl c-markdown">
          <MDXProvider
            components={{
              a: MdxLink,
            }}
          >
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </Wrap>
      </div>
    </>
  );
}

export default animalPage;

export const query = graphql`
  query Animal($locale: String!, $title: String!) {
    mdx(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        intro
      }
      body
    }
    nav: allMdx(
      filter: {
        frontmatter: { templateKey: { eq: "animal" } }
        fields: { locale: { eq: $locale } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`;
